import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Flex } from 'components/Layout'
import { Text } from 'components/Typography'

const truncate = (string, maxChar) => {
  if (string && string.length > maxChar) return `${string.substring(0, maxChar)}...`
  else return string
}

const CollapseWrapper = styled(Text)`
  cursor: pointer;
`

const TextCollapse = ({ message, maxLength, expandText, collapseText, textColor, isWorking }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [messageStart, setMessageStart] = useState('')
  const messageEnd = message.substr(maxLength) || ''

  useEffect(() => {
    setMessageStart(truncate(message, maxLength))
  }, [message])

  const updateMessage = () => {
    if (!isOpen) {
      setMessageStart(`${message}`)
    } else {
      setMessageStart(truncate(message, maxLength))
    }
    setIsOpen(!isOpen)
  }

  return (
    <Flex>
      <Text color={textColor} whiteSpce="pre-line" textAlign="left">
        {messageStart}
        {messageEnd && !isOpen && isWorking && (
          <CollapseWrapper as="span" color="primary" onClick={() => updateMessage()}>
            {` ${expandText}`}
          </CollapseWrapper>
        )}
        {isOpen && (
          <CollapseWrapper as="span" color="primary" onClick={() => updateMessage()}>
            {` ${collapseText}`}
          </CollapseWrapper>
        )}
      </Text>
    </Flex>
  )
}

TextCollapse.defaultProps = {
  maxLength: 190,
  expandText: 'Read more',
  collapseText: 'Read less',
  textColor: 'secondaryText',
  isWorking: true,
}

TextCollapse.propTypes = {
  message: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  textColor: PropTypes.string,
  isWorking: PropTypes.bool,
}

export default TextCollapse
