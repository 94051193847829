/* eslint-disable camelcase */
import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import format from 'date-fns/format'
import { space, colors, radius, COLOR_CONSTANTS } from 'theme'
import App from 'App'
import SEO from 'components/SEO'
import { Box, Flex, Grid } from 'components/Layout'
import { H1, Text } from 'components/Typography'
import Container from 'components/Container'
import Footer from 'components/Footer'
import ExternalLink from 'components/Link'
import ImageWithFallback from 'components/ImageWithFallback'
import LocalImage from 'components/LocalImage'
import TextCollapse from 'components/TextCollapse'
import company from 'static/svg/company.svg'
import back from 'static/svg/back.svg'
import badge_small from 'static/svg/badge_small.svg'
import no_badge from 'static/svg/badge_non_verified.svg'
import Button from 'components/Button'

const StyledFlex = styled(Grid)`
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  box-shadow: 0px 2px 7px rgba(39, 40, 49, 0.05);
  transition: opacity 0.5s cubic-bezier(0.77, 0.2, 0.05, 1);
`

const LogoWrapper = styled(Box)`
  display: flex;
  align-items: center;
  width: 140px;
  height: 140px;
  position: relative;
  padding: ${space.s};
  background-color: ${colors.white};
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledImage = styled(ImageWithFallback)`
  width: 100%;
  pointer-events: none;
`

const StyledDiv = styled.div`
  transform: rotate(180deg);
`

const ButtonPag = styled('button')`
  user-select: none;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 30px;
  height: 30px;
  outline: none;
  background: ${colors.primary};
  border: solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${colors.white};
  color: ${COLOR_CONSTANTS.SALUTE};
  border-width: 1px;
  ${({ isDisabled }) =>
    isDisabled &&
    `
    color:${COLOR_CONSTANTS.SOLITUDE}
    pointer-events: none;
  `}
`

const StyledText = styled(Text)`
  &:hover {
    color: ${colors.primary};
  }
`

const Lists = ({ pageContext: { list } }) => {
  const { agencies, name, description, slug, numPages, currentPage, updated } = list
  const date = new Date(updated).getTime()
  const seoDate = format(date, 'MMMM yyyy')
  const listDate = format(date, 'MMMM d, yyyy')

  const utm_query = `?utm_source=list&utm_medium=${slug}`
  const utm_query_for_site = `?utm_source=agencyvista.com&utm_medium=referral&utm_campaign=top_local_agency&utm_term=${slug}`

  return (
    <App>
      <SEO
        title={`${name}. ${seoDate} Reviews.`}
        description={`${description}. Last updated ${seoDate}.`}
        path={`/lists/${slug}/`}
      />
      <Container maxWidth="1080px">
        <Flex mt="xl" flexDirection="column" alignItems="center" textAlign="center">
          <H1>{name}</H1>
          <Text mt="m" color="secondaryText" opacity={0.8} fontSize="l">
            {description || `Connect with ${name} that we have researched, compiled and verified.`}
          </Text>
          <Text mt="s" color="secondaryText" opacity={0.8} fontSize="l">{`Last updated: ${listDate}`}</Text>
        </Flex>
      </Container>
      <Flex flexDirection="column" px="m">
        <Grid
          mt="l"
          py="m"
          px="s"
          width="100%"
          gridTemplateColumns={{ mobile: '1fr', desktop: 'repeat(2, 1fr)' }}
          gridGap={{ mobile: 'm', desktop: 'l' }}
        >
          {agencies.map(
            ({
              name,
              slug,
              profile_url,
              picture_url: logo,
              founded,
              city,
              website,
              verified,
              location,
              description,
            }) => {
              const locationInfo = city || location
              const info = []
              if (founded) {
                info.push(`Founded ${founded}`)
              }
              if (locationInfo) {
                info.push(locationInfo)
              }
              return (
                <StyledFlex key={slug} flexDirection="column" mt="m" px={{ mobile: 's', tablet: 'l' }} py="l">
                  <Grid
                    gridTemplateColumns={{ mobile: '70px auto', tablet: '140px auto' }}
                    gridGap={{ mobile: 's', tablet: 'm', desktop: 'l' }}
                  >
                    <Flex>
                      <LogoWrapper
                        width={{ mobile: '70px', tablet: '140px' }}
                        height={{ mobile: '70px', tablet: '140px' }}
                        href={`${profile_url}${utm_query}`}
                        target="_blank"
                        as="a"
                      >
                        {logo ? (
                          <React.Fragment>
                            {verified ? (
                              <Box
                                position="absolute"
                                left={{ mobile: '-35px', tablet: '-60px' }}
                                top={{ mobile: '-50px', tablet: '-50px' }}
                              >
                                <LocalImage
                                  source={badge_small}
                                  width={{ mobile: '70px', tablet: '80px', desktop: '80px' }}
                                />
                              </Box>
                            ) : (
                              <Box
                                position="absolute"
                                left={{ mobile: '-35px', tablet: '-60px' }}
                                top={{ mobile: '-50px', tablet: '-50px' }}
                              >
                                <LocalImage
                                  source={no_badge}
                                  width={{ mobile: '70px', tablet: '80px', desktop: '80px' }}
                                />
                              </Box>
                            )}
                            <StyledImage source={logo} alt={`${name} | Agency Vista`} fallbackSource={company} />
                          </React.Fragment>
                        ) : (
                          <StyledImage source={company} />
                        )}
                      </LogoWrapper>
                    </Flex>
                    <Flex flexDirection="column" ml={{ mobile: 0, tablet: 'm', desktop: 'm' }} alignItems="flex-start">
                      <Flex width="100%" justifyContent="space-between" alignItems="baseline">
                        <Flex flexDirection="column">
                          <StyledText
                            textAlign="left"
                            fontSize={{ mobile: 'l', tablet: 'xxl' }}
                            as="a"
                            href={`${profile_url}${utm_query}`}
                            target="_blank"
                            color="primaryText"
                          >
                            {name}
                          </StyledText>
                        </Flex>
                        <Box minWidth={{ mobile: '85px', mobileLarge: '105px' }}>
                          <Button.Gray as={ExternalLink} href={`${profile_url}${utm_query}`} target="_blank" isSmall>
                            View Profile
                          </Button.Gray>
                        </Box>
                      </Flex>
                      <Flex flexDirection="column">
                        <Flex flexWrap="wrap">
                          {info.length > 0 && (
                            <Text as="span" color="secondaryText" antiAliased>
                              {info.join(' · ')}&nbsp;
                            </Text>
                          )}
                        </Flex>
                        {website && (
                          <Text as="span" color="secondaryText" antiAliased>
                            <ExternalLink
                              color="secondaryText"
                              href={`${website}${utm_query_for_site}`}
                              target="_blank"
                              rel="noopener noreferrer nofollow"
                            >
                              <Text as="span" color="secondaryText" antiAliased>
                                {website}
                              </Text>
                            </ExternalLink>
                          </Text>
                        )}
                      </Flex>
                      {description && (
                        <Flex display={{ mobile: 'none', desktop: 'flex' }} mt="s">
                          <TextCollapse message={description} />
                        </Flex>
                      )}
                    </Flex>
                  </Grid>
                  {description && (
                    <Flex mt="s" display={{ mobile: 'flex', desktop: 'none' }}>
                      <TextCollapse message={description} />
                    </Flex>
                  )}
                </StyledFlex>
              )
            }
          )}
        </Grid>
        <Flex width="100%" justifyContent="space-between" my="l">
          <ButtonPag
            as={ExternalLink}
            href={currentPage > 1 ? `/lists/${slug}/page/${currentPage - 1}` : ''}
            isDisabled={currentPage <= 1}
          >
            <Flex alignItems="center" mr="s" height="100%">
              <LocalImage source={back} mr="s" />
              <Text>Previous</Text>
            </Flex>
          </ButtonPag>
          <Flex>
            <ButtonPag>
              <Text px="s">
                {currentPage} of {numPages}
              </Text>
            </ButtonPag>
          </Flex>
          <ButtonPag
            as={ExternalLink}
            href={currentPage < numPages ? `/lists/${slug}/page/${currentPage + 1}` : ''}
            isDisabled={currentPage >= numPages}
          >
            <Flex alignItems="center" ml="s" height="100%">
              <Text>Next</Text>
              <StyledDiv>
                <LocalImage source={back} mr="s" />
              </StyledDiv>
            </Flex>
          </ButtonPag>
        </Flex>
      </Flex>
      <Box mt="xxl">
        <Footer />
      </Box>
    </App>
  )
}

Lists.propTypes = {
  pageContext: PropTypes.shape({
    list: PropTypes.object.isRequired,
  }).isRequired,
}

export default Lists
